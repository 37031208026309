import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

interface ImageType {
  url: string;
}

interface SlideProps {
  active: boolean;
}

interface ButtonProps {
  active: boolean;
}

const SlideshowContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 94vw;
  height: 84vh;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
`;

const SlideshowWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Slide = styled.div<SlideProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain; /* Ensures the whole image is visible */
  background-position: center;
  background-repeat: no-repeat; /* Prevents the image from repeating */
  transition: opacity 0.5s ease-in-out;
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

const Controls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
`;

const Dot = styled.button<ButtonProps>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: ${(props) =>
    props.active ? 'white' : 'rgba(255, 255, 255, 0.5)'};
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) =>
      props.active ? 'white' : 'rgba(255, 255, 255, 0.8)'};
  }
`;

const ControlPanel = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
`;

const Button = styled.button<ButtonProps>`
  padding: 8px 16px;
  background-color: ${(props) =>
    props.active ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.7)'};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const SpeedControl = styled.div`
  display: flex;
  gap: 10px;
`;

const Slideshow: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [images, setImages] = useState<ImageType[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isAutoPlay, setIsAutoPlay] = useState<boolean>(true);
  const [speed, setSpeed] = useState<number>(3000);

  useEffect(() => {
    if (id) {
      axios
        .get<{ files: ImageType[] }>(`/api/listOfSlideShow/${id}`)
        .then((res) => {
          setImages(res.data.files);
        })
        .catch((err: Error) => console.log(err.message));
    }
  }, [id]);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isAutoPlay) {
      timer = setInterval(nextSlide, speed);
    }
    return () => clearInterval(timer);
  }, [isAutoPlay, speed, nextSlide]);

  const goToSlide = (index: number): void => {
    setCurrentIndex(index);
  };

  const toggleAutoPlay = (): void => {
    setIsAutoPlay((prev) => !prev);
  };

  const setSlideSpeed = (newSpeed: number): void => {
    setSpeed(newSpeed);
  };

  return (
    <SlideshowContainer>
      <SlideshowWrapper>
        {images.map((img, index) => (
          <Slide
            key={index}
            active={index === currentIndex}
            style={{ backgroundImage: `url(${img.url})` }}
          />
        ))}
      </SlideshowWrapper>
      <Controls>
        {images.map((_, index) => (
          <Dot
            key={index}
            active={index === currentIndex}
            onClick={() => goToSlide(index)}
          />
        ))}
      </Controls>
      <ControlPanel>
        <Button active={isAutoPlay} onClick={toggleAutoPlay}>
          {isAutoPlay ? 'Pause' : 'Play'}
        </Button>
        <SpeedControl>
          <Button active={speed === 8000} onClick={() => setSlideSpeed(8000)}>
            Slow
          </Button>
          <Button active={speed === 5000} onClick={() => setSlideSpeed(5000)}>
            Normal
          </Button>
          <Button active={speed === 3000} onClick={() => setSlideSpeed(3000)}>
            Fast
          </Button>
        </SpeedControl>
      </ControlPanel>
    </SlideshowContainer>
  );
};

export default Slideshow;
